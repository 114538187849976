const ENV = {
  LCL: {
    API_SERVER: 'https://dev-api-stella.bappartners.com/apiV1',
    // API_SERVER: 'http://localhost:8080/apiV1',
    SOCKET_KEY: 'https://dev-api-stella.bappartners.com',
    // SOCKET_KEY: 'http://localhost:9003'
    REACT_APP_GOOGLE_API_KEY: 'AIzaSyB6rJOxJ5TvRMqXcZCv49Oy8EScg6nfKVQ'
  },
  DEV: {
    API_SERVER: 'DEV_CI_API_URL',
    SOCKET_KEY: 'DEV_CI_SOCKET_KEY',
    REACT_APP_GOOGLE_API_KEY: 'DEV_CI_REACT_APP_GOOGLE_API_KEY'
  },
  STG: {
    API_SERVER: 'https://staging.new-stella-backend.stella-cloud.com/apiV1',
    SOCKET_KEY: 'https://staging.new-stella-backend.stella-cloud.com',
    REACT_APP_GOOGLE_API_KEY: 'AIzaSyBxt4PaOwvioABfcfZtwtQ4AEniX5-h3g0'
  },
  PRO: {
    API_SERVER: 'https://api.neo.stella-cloud.com/apiV1',
    SOCKET_KEY: 'https://api.neo.stella-cloud.com',
    REACT_APP_GOOGLE_API_KEY: 'PRO_CI_REACT_APP_GOOGLE_API_KEY'
  },
};
const config = ENV[process.env.REACT_APP_STAGE || 'LCL'];

const getConfig = (name, defaultValue) => {
  return process.env[name] || config[name] || defaultValue;
};

export { getConfig };
